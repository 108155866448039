/* src/Chat.css */
.chat-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .chat-window {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    height: 400px;
    overflow-y: scroll;
  }
  
  .message {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 4px;
    max-width: 70%;
  }
  
  .user-message {
    background-color: #007bff;
    color: #fff;
    align-self: flex-end;
    margin-left: auto;
  }
  
  .server-message {
    background-color: #f1f0f0;
    color: #333;
    align-self: flex-start;
    margin-right: auto;
  }
  
  .chat-input {
    display: flex;
    margin-top: 10px;
  }
  
  .chat-input input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
  }
  
  .chat-input button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }