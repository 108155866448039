.resend-button-small {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .resend-button-small:hover {
    background-color: #0056b3;
  }
  
  .resend-button-small:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.4);
  }